@if (won$ | async) {
  <div
    class="container"
    [ngClass]="{ 'bounce-in-down': (awardAnimationPlayed$ | async) === false }">
    <div class="title-container">
      <span class="title">{{ 'winnings.title' | translate }}</span>
    </div>

    <div class="winning-container">
      @for (winning of winnings$ | async; track winning.collectibleGoal.uuid) {
        <div class="winning-element">
          @if (winning.collectibleGoal.freeSpinsAmount > 0) {
            @if ((game$ | async)?.featuredProducts?.length) {
              <img
                class="game-icon"
                [src]="(game$ | async)!.featuredProducts[0].asset.url"
                alt="Game Asset" />
            }
          }
          @if (
            !winning.collectibleGoal.freeSpinsAmount ||
            !(game$ | async)?.featuredProducts ||
            (game$ | async)?.featuredProducts?.length === 0
          ) {
            <img
              class="game-icon"
              [src]="winning.collectibleGoal.icon.url"
              alt="Goal Icon" />
          }
          <div class="winnings-info">
            <span class="winnings-title">{{
              'winnings.prize' | translate
            }}</span>
            <span class="winnings-prize">{{
              winning.collectibleGoal.prizeName
            }}</span>
          </div>
        </div>
      }
      <p class="winnings-info">{{ 'winnings.prizeInfo' | translate }}</p>
    </div>

    @if ((game$ | async)!.ctaLink) {
      <button
        class="action-button"
        [class]="(isMobile$ | async) ? 'med' : 'big'"
        (click)="playNow()">
        {{ 'winnings.claimNow' | translate }}
      </button>
    }
    @if ('winnings.buttonDismiss' | translate) {
      <button
        class="action-button outline"
        [class]="(isMobile$ | async) ? 'med' : 'big'"
        (click)="dismiss()">
        {{ 'winnings.buttonDismiss' | translate }}
      </button>
    }
  </div>
} @else {
  <div
    class="container"
    [ngClass]="{ 'bounce-in-down': (awardAnimationPlayed$ | async) === false }">
    <div class="title-container">
      <span class="title">{{ 'winnings.noPrizes' | translate }}</span>
      <span class="subtitle">{{ 'winnings.comeBack' | translate }}</span>
      @for (collectible of collectibles$ | async; track collectible.goal.uuid) {
        <div class="winning-element">
          <img
            class="game-icon"
            [src]="collectible.goal.icon.url"
            alt="Goal Icon" />
          <div class="winnings-info">
            <span class="winnings-title">{{
              'winnings.collected' | translate
            }}</span>
            <span class="winnings-prize"
              >+{{ collectible.inThisSpinCollectedCount }}
              {{ collectible.goal.icon.label }}</span
            >
          </div>
        </div>
      }
    </div>
    @if ((game$ | async)?.ctaLink) {
      <div class="upselling-container">
        @for (upselling of upselling$ | async; track upselling.img) {
          <div class="upselling-element">
            <img class="game-icon" [src]="upselling.img" alt="Upselling Icon" />
            <div class="upselling-info">
              <span class="upselling-title">{{
                upselling.title | translate
              }}</span>
              <span class="upselling-game">{{ upselling.game }}</span>
              <button class="action-button small" (click)="playNow()">
                {{ 'winnings.playNow' | translate }}
              </button>
            </div>
          </div>
        }
      </div>
    }
    @if ('winnings.buttonDismiss' | translate) {
      <button
        class="action-button outline"
        [class]="(isMobile$ | async) ? 'med' : 'big'"
        (click)="dismiss()">
        {{ 'winnings.buttonDismiss' | translate }}
      </button>
    }
    @if ((game$ | async)?.depositLink) {
      <button
        class="action-button"
        (click)="deposit()"
        [class]="(isMobile$ | async) ? 'med' : 'big'">
        {{ 'winnings.makeDeposit' | translate }}
      </button>
    }
  </div>
}
@if (showNextSpinInfo$ | async) {
  <app-next-spin-info></app-next-spin-info>
}
@if (isFromHub || (showCloseButton$ | async)) {
  <img
    tabindex="0"
    (click)="close()"
    (keyup.enter)="close()"
    [class.mobile]="isMobile$ | async"
    class="close"
    alt="Close"
    src="assets/icons/header-close.png" />
}
