import { Component } from '@angular/core';
import { SessionService } from '@services/session.service';
import { CollectiblesService } from '@services/collectibles.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent {
  collectibles$ = this.collectibleService.collectibles$;
  game$ = this.session.game$;
  isGBCountry$ = this.session.isGBCountry$;
  charityValue$ = this.session.game$.pipe(
    map((game) => game?.charityValue !== null),
  );
  spinsPerDay$ = this.game$.pipe(
    map((game) => (game?.spinsPerDay ? game!.spinsPerDay.toString() : '')),
  );

  constructor(
    private session: SessionService,
    private collectibleService: CollectiblesService,
  ) {}
}
