export var UserAction;
(function (UserAction) {
  UserAction["CLICK_DEPOSIT"] = "CLICK_DEPOSIT";
  UserAction["CLICK_DEEPLINKING"] = "CLICK_DEEPLINKING";
  UserAction["CLICK_CLAIM_PRIZE"] = "CLICK_CLAIM_PRIZE";
  UserAction["ABANDON_GAME"] = "ABANDON_GAME";
  UserAction["TRANSACTION_ERROR"] = "TRANSACTION_ERROR";
  UserAction["CLICK_LOGIN"] = "CLICK_LOGIN";
  UserAction["CLICK_SIGNUP"] = "CLICK_SIGNUP";
  UserAction["SIGNUP_CONFIRMED"] = "SIGNUP_CONFIRMED";
  UserAction["START_GAME"] = "START_GAME";
  UserAction["EDIT_GAME"] = "EDIT_GAME";
  UserAction["VIEW_GAME_SUMMARY"] = "VIEW_GAME_SUMMARY";
  UserAction["FINISH_GAME"] = "FINISH_GAME";
  UserAction["GAME_FOUND"] = "GAME_FOUND";
  UserAction["NO_GAME_FOUND"] = "NO_GAME_FOUND";
  UserAction["NO_THEME_FOUND"] = "NO_THEME_FOUND";
  UserAction["NO_COLLECTIBLE_CONFIG_FOUND"] = "NO_COLLECTIBLE_CONFIG_FOUND";
  UserAction["NO_ICON_CHANCE_CONFIG_FOUND"] = "NO_ICON_CHANCE_CONFIG_FOUND";
  UserAction["NO_PAYTABLE_FOUND"] = "NO_PAYTABLE_FOUND";
  UserAction["NO_SEGMENT_CONFIG_FOUND"] = "NO_SEGMENT_CONFIG_FOUND";
})(UserAction || (UserAction = {}));